import * as THREE from 'three';
import DefaultPhysicsElement from '../../src/engine/elements/DefaultPhysicsElement';
import GameElement from '../../src/engine/elements/GameElement';
export default class Player extends GameElement {
    constructor() {
        super();
        this.playerMesh = new THREE.Mesh(new THREE.BoxBufferGeometry(0.45, 1.6, 0.2), new THREE.MeshToonMaterial({ color: 0x00ff00 })).translateY(0.8);
        this.player = new DefaultPhysicsElement(this.playerMesh, undefined, {
            wireframe: false,
            renderMesh: false,
            updatePosition: false,
            updateRotation: false,
        });
        this.onPlayerCollisionListeners = [];
        this.playerMesh.visible = true;
        this.player
            .getBody()
            .addEventListener('collide', ({ body }) => this.onPlayerCollisionListeners.forEach(listener => listener(body)));
    }
    onPlayerCollision(listener) {
        this.onPlayerCollisionListeners.push(listener);
    }
    getPlayer() {
        return this.player;
    }
    render() {
        return this.player;
    }
}
