import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import DefaultPhysicsElement from '../../src/engine/elements/DefaultPhysicsElement';
import GameElement from '../../src/engine/elements/GameElement';
export default class Floor extends GameElement {
    constructor() {
        super();
        this.floorMesh = new THREE.Mesh(new THREE.PlaneGeometry(10, 10), new THREE.MeshToonMaterial({ color: 0x777777 }));
        this.subfloor = new THREE.Mesh(new THREE.PlaneGeometry(150, 150), new THREE.MeshToonMaterial({ color: 0x111111 }));
        this.floor = new DefaultPhysicsElement(this.floorMesh, {
            shape: new CANNON.Plane(),
        });
        this.floorMesh.receiveShadow = true;
        this.floor.rotation.x = -Math.PI * 0.5;
        this.subfloor.receiveShadow = true;
        this.subfloor.rotation.x = -Math.PI * 0.5;
        this.subfloor.position.y = -0.01;
    }
    render() {
        return [this.floor, this.subfloor];
    }
}
