import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import GameElement from '../../src/engine/elements/GameElement';
import DefaultPhysicsElement from '../../src/engine/elements/DefaultPhysicsElement';
import gsap from 'gsap';
export default class Dolly extends GameElement {
    constructor(player, bat) {
        super();
        this.dolly = new THREE.Group();
        this.onDollyOutsideAllowedAreaListeners = [];
        this.callDollyOutsideAllowedAreaListenerTime = 0;
        this.state = {
            grip: undefined,
        };
        this.bat = bat;
        this.player = player.getPlayer();
        player.onPlayerCollision((body) => this.onPlayerCollision(body));
    }
    onEnterScene() {
        this.webxr = this.engine.getWebxr();
        this.webxr.onSessionStart(() => this.onSessionStart());
    }
    onPlayerCollision(body) {
        if (!this.state.grip)
            return;
        const { x, z } = body.velocity;
        const force = 0.15;
        this.collisionTween = gsap.to(this.dolly.position, {
            x: `+=${x * force}`,
            z: `+=${z * force}`,
        });
    }
    onSessionStart() {
        this.camera = this.engine.getCamera();
        const grip = this.webxr.getGrip();
        grip.add(this.bat.getBat());
        this.state.grip = new DefaultPhysicsElement(grip, {
            shape: new CANNON.Cylinder(0.06, 0.06, 1.33),
            positionOffset: new CANNON.Vec3(0, 0, -0.48),
            quaternionOffset: new CANNON.Quaternion(0.7068252, 0, 0, 0.7073883),
        }, {
            wireframe: false,
            renderMesh: false,
            updatePosition: false,
            updateDirection: 'meshToBody',
        });
        this.dolly.add(this.camera, this.state.grip.getMesh());
    }
    checkDollyOutsideAllowedArea(elapsedTime) {
        if (!this.camera ||
            (this.camera.position.x <= 5 &&
                this.camera.position.x >= -5 &&
                this.camera.position.z <= 5 &&
                this.camera.position.z >= -5))
            return;
        const passedSeconds = elapsedTime - this.callDollyOutsideAllowedAreaListenerTime;
        if (passedSeconds < 1)
            return;
        this.callDollyOutsideAllowedAreaListenerTime = elapsedTime;
        this.resetDollyPosition();
        this.onDollyOutsideAllowedAreaListeners.forEach(listener => listener());
    }
    resetDollyPosition() {
        var _a;
        (_a = this.collisionTween) === null || _a === void 0 ? void 0 : _a.kill();
        this.dolly.position.z = 0;
        this.dolly.position.x = 0;
    }
    getDolly() {
        return this.dolly;
    }
    onDollyOutsideAllowedArea(listener) {
        this.onDollyOutsideAllowedAreaListeners.push(listener);
    }
    tick(elapsedTime) {
        gsap.ticker.tick();
        this.checkDollyOutsideAllowedArea(elapsedTime);
        this.moveGrip();
        this.movePlayer();
    }
    moveGrip() {
        if (!this.state.grip)
            return;
        this.state.grip
            .getBody()
            .position.set(this.state.grip.getMesh().position.x + this.dolly.position.x, this.state.grip.getMesh().position.y + this.dolly.position.y, this.state.grip.getMesh().position.z + this.dolly.position.z);
    }
    movePlayer() {
        if (!this.camera)
            return;
        this.player
            .getBody()
            .quaternion.set(0, this.camera.quaternion.y, 0, this.camera.quaternion.w);
        this.player
            .getBody()
            .position.set(this.camera.position.x, this.camera.position.y - this.player.getMesh().position.y, this.camera.position.z);
    }
    render() {
        return [this.dolly, this.state.grip];
    }
}
