var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as THREE from 'three';
import GameElement from '../../src/engine/elements/GameElement';
export default class ThrowDirection extends GameElement {
    constructor(dolly) {
        super();
        this.onDirectionChangeListeners = [];
        this.directionMesh = new THREE.Mesh(new THREE.PlaneGeometry(0.6, 10), new THREE.MeshToonMaterial({ color: 0x156cff }));
        this.directionMesh.receiveShadow = true;
        this.directionMesh.rotation.x = -Math.PI * 0.5;
        this.directionMesh.position.y = 0.001;
        this.changeDirection();
        dolly.onDollyOutsideAllowedArea(() => this.onPlayerOutsideAllowedArea());
    }
    onPlayerOutsideAllowedArea() {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeout(this.changeDirectionTimeout);
            this.directionMesh.material.color.set(0xff4d1a);
            yield this.blinkDirectionMesh(10, 300);
            this.directionMesh.material.color.set(0x156cff);
            this.directionMesh.visible = false;
            setTimeout(() => {
                this.directionMesh.visible = true;
                this.direction = undefined;
                this.changeDirection();
            }, 3000);
        });
    }
    changeDirection() {
        const timeBetweenChanges = this.getRandomBetween(7000, 9000);
        this.changeDirectionAxis();
        this.checkDirectionAxisChange();
        clearTimeout(this.changeDirectionTimeout);
        this.changeDirectionTimeout = setTimeout(() => {
            this.changeDirection();
        }, timeBetweenChanges);
    }
    changeDirectionAxis() {
        this.lastDirection = this.direction;
        const randomAxis = Math.round(Math.random() * 3);
        switch (randomAxis) {
            case 0:
                return (this.direction = '+x');
            case 1:
                return (this.direction = '-x');
            case 2:
                return (this.direction = '+z');
            case 3:
                return (this.direction = '-z');
            default:
                return (this.direction = '+x');
        }
    }
    blinkDirectionMesh(quantity, ms = 150) {
        return new Promise(resolve => {
            this.directionMesh.visible = !this.directionMesh.visible;
            if (quantity > 0)
                setTimeout(() => __awaiter(this, void 0, void 0, function* () { return resolve(yield this.blinkDirectionMesh(quantity - 1)); }), ms);
            else {
                this.directionMesh.visible = true;
                resolve();
            }
        });
    }
    checkDirectionAxisChange() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.direction === this.lastDirection)
                return;
            this.onDirectionChangeListeners.forEach(listener => listener(this.direction));
            this.updateDirectionMesh();
            yield this.blinkDirectionMesh(5);
        });
    }
    updateDirectionMesh() {
        switch (this.direction) {
            case '+x':
                this.directionMesh.position.x = 4.7;
                this.directionMesh.position.z = 0;
                this.directionMesh.rotation.z = 0;
                break;
            case '-x':
                this.directionMesh.position.x = -4.7;
                this.directionMesh.position.z = 0;
                this.directionMesh.rotation.z = 0;
                break;
            case '+z':
                this.directionMesh.position.x = 0;
                this.directionMesh.position.z = 4.7;
                this.directionMesh.rotation.z = Math.PI * 0.5;
                break;
            case '-z':
                this.directionMesh.position.x = 0;
                this.directionMesh.position.z = -4.7;
                this.directionMesh.rotation.z = Math.PI * 0.5;
                break;
            default:
                return;
        }
    }
    getRandomBetween(min, max) {
        return Math.random() * (max - min) + min;
    }
    onDirectionChange(listener) {
        listener(this.direction);
        this.onDirectionChangeListeners.push(listener);
    }
    render() {
        return this.directionMesh;
    }
}
