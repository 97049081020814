import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
export default class Bat {
    constructor() {
        this.loader = new GLTFLoader();
        this.loadBat();
    }
    loadBat() {
        this.loader.load('/models/bat.glb', ({ scene: model }) => {
            this.bat = model.getObjectByName('bat');
        });
    }
    getBat() {
        return this.bat;
    }
}
