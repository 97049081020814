var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import DefaultPhysicsElement from '../../src/engine/elements/DefaultPhysicsElement';
import GameElement from '../../src/engine/elements/GameElement';
export default class ThrowObjects extends GameElement {
    constructor(dolly, player, throwDirection) {
        super();
        this.sphereGeometry = new THREE.SphereBufferGeometry(1, 20, 20);
        this.sphereMaterial = new THREE.MeshToonMaterial();
        this.state = {
            spheres: [],
        };
        this.player = player.getPlayer();
        throwDirection.onDirectionChange((direction) => this.onDirectionChange(direction));
        dolly.onDollyOutsideAllowedArea(() => this.onPlayerOutsideAllowedArea());
    }
    onEnterScene() {
        this.camera = this.engine.getCamera();
    }
    onDirectionChange(direction) {
        clearTimeout(this.throwSphereTimeout);
        this.direction = direction;
        this.state.spheres = [];
        this.throwSphere();
    }
    onPlayerOutsideAllowedArea() {
        return __awaiter(this, void 0, void 0, function* () {
            clearTimeout(this.throwSphereTimeout);
            this.state.spheres = [];
        });
    }
    getDistance() {
        return (Math.random() - 0.5) * 20;
    }
    throwSphere() {
        const nextThrow = this.getRandomBetween(1000, 3000);
        this.throwSphereTimeout = setTimeout(() => {
            const sphere = this.createSphere();
            this.setSpherePosition(sphere);
            const playerHeight = this.camera.position.y;
            const xDirection = this.camera.position.x - sphere.position.x;
            const zDirection = this.camera.position.z - sphere.position.z;
            const yDirection = this.getRandomBetween(0, playerHeight);
            sphere
                .getBody()
                .applyLocalImpulse(new CANNON.Vec3(xDirection, yDirection, zDirection), new CANNON.Vec3(0, 0, 0));
            this.state.spheres = [...this.state.spheres, sphere];
            this.throwSphere();
        }, nextThrow);
    }
    setSpherePosition(sphere) {
        const units = 20;
        const distance = this.getDistance();
        sphere.position.y = 5;
        switch (this.direction) {
            case '+x':
                sphere.position.x = units;
                sphere.position.z = distance;
                break;
            case '-x':
                sphere.position.x = -units;
                sphere.position.z = distance;
                break;
            case '+z':
                sphere.position.x = distance;
                sphere.position.z = units;
                break;
            case '-z':
                sphere.position.x = distance;
                sphere.position.z = -units;
                break;
            default:
                sphere.position.x = units;
                sphere.position.z = distance;
        }
    }
    createSphere() {
        const radius = this.getRandomBetween(0.1, 0.9);
        const sphere = new DefaultPhysicsElement(new THREE.Mesh(this.sphereGeometry, this.sphereMaterial), { mass: 1, shape: new CANNON.Sphere(radius) });
        sphere.getMesh().scale.set(radius, radius, radius);
        sphere.getMesh().castShadow = true;
        return sphere;
    }
    getRandomBetween(min, max) {
        return Math.random() * (max - min) + min;
    }
    render() {
        return this.state.spheres;
    }
}
